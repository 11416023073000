import { Text, useLocalStorage, styled } from '@obvio/app'
import { Button, Stack } from '@obvio/ui'
import { useState } from 'react'

import { StyledModal } from './components'

import type { ReactElement } from 'react'

const SvgButton = styled.svg`
  cursor: pointer;
  :hover {
    fill: white;
  }
`

export function AgeModal(): ReactElement {
  const [consentValue, setConsentValue] = useLocalStorage('ageConsent')
  const [declined, setDeclined] = useState(false)
  return (
    <StyledModal
      open={Boolean(consentValue === undefined)}
      $declined={declined}
    >
      <Stack kind="vertical" spacing="medium">
        {declined ? (
          <>
            <Text tag="h1">
              Nie możesz oglądać zawartości strony.
              <br />
              Nie masz ukończonych 18 lat.
            </Text>
            <Text>
              Strona zawiera materiały dotyczące napojów alkoholowych
              przeznaczonych wyłącznie dla osób pełnoletnich.
              <br />Z serwisu mogą korzystać tylko osoby pełnoletnie.
            </Text>
            <Button kind="transparent" onClick={() => setDeclined(false)}>
              Powrót
            </Button>
          </>
        ) : (
          <>
            <Text tag="h1">Czy masz ukończone 18 lat?</Text>
            <Text>
              Strona zawiera materiały dotyczące napojów alkoholowych
              przeznaczonych wyłącznie dla osób pełnoletnich.
              <br />Z serwisu mogą korzystać tylko osoby pełnoletnie.
            </Text>
            <Stack>
              <SvgButton
                width="56"
                height="183"
                viewBox="0 0 56 183"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setConsentValue('true')}
              >
                <path
                  d="M49.6837 180.141L49.6836 180.141L18.2919 182.483C18.2918 182.483 18.2918 182.483 18.2918 182.483C15.917 182.66 13.6403 181.407 12.4357 179.237L12.4356 179.237L1.34761 159.264L1.34737 159.264C0.791174 158.265 0.499999 157.126 0.499999 155.969L0.499993 21.6919C0.499993 19.406 1.63697 17.2917 3.48494 16.102C3.48498 16.1019 3.48501 16.1019 3.48505 16.1019L26.7595 1.1273L26.489 0.706816L26.7595 1.12727C28.9144 -0.259416 31.7658 0.746123 32.6558 3.26506L32.6559 3.26529L55.1097 66.7114L55.11 66.7122C55.3684 67.4382 55.5 68.2114 55.5 68.9898L55.5 173.598C55.5 177.059 52.9439 179.898 49.6837 180.141Z"
                  stroke="#686235"
                />
                <path
                  d="M19.2075 97L19.2075 88.654L20.4945 88.654L20.4945 97L19.2075 97ZM16.3605 89.07L16.3605 87.9L23.3415 87.9L23.3415 89.07L16.3605 89.07ZM30.1297 97L26.5287 87.9L27.8287 87.9L31.4947 97L30.1297 97ZM22.7977 97L26.4637 87.9L27.7637 87.9L24.1627 97L22.7977 97ZM24.5267 94.569L24.5267 93.425L29.7657 93.425L29.7657 94.569L24.5267 94.569ZM33.6595 94.114L33.2825 92.762L37.9235 87.9L39.5485 87.9L33.6595 94.114ZM32.8275 97L32.8275 87.9L34.0625 87.9L34.0625 97L32.8275 97ZM38.1445 97L34.4395 92.372L35.3105 91.566L39.7175 97L38.1445 97Z"
                  fill="#686235"
                />
              </SvgButton>
              <SvgButton
                width="54"
                height="183"
                viewBox="0 0 54 183"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setDeclined(true)}
              >
                <path
                  d="M1.45584 27.9938L1.45608 27.9933L13.2921 4.92068C14.8725 1.84689 18.0868 0.127643 21.3628 0.568432C21.3629 0.568443 21.363 0.568455 21.363 0.568466L34.6891 2.36893C34.6893 2.36894 34.6894 2.36896 34.6895 2.36898C36.7698 2.65352 38.6651 3.77922 39.9657 5.51636C39.9657 5.51641 39.9657 5.51646 39.9658 5.51652L51.7933 21.3282L51.7933 21.3282C52.8984 22.8053 53.5 24.6315 53.5 26.5164V164.774C53.5 167.709 52.0388 170.424 49.6598 171.949C49.6597 171.949 49.6596 171.949 49.6595 171.949L35.0881 181.273L35.0878 181.273C32.8585 182.701 30.1045 182.892 27.7143 181.796C27.7142 181.796 27.7141 181.796 27.714 181.796L11.8937 174.53C9.64366 173.497 7.9614 171.434 7.34805 168.926L7.34797 168.925L0.753538 142.034L0.753416 142.033C0.585895 141.353 0.5 140.647 0.5 139.941V31.9771C0.5 30.5847 0.827422 29.2156 1.45584 27.9938Z"
                  stroke="#686235"
                />
                <path
                  d="M23.8826 97L17.7206 88.615L18.7346 87.9L24.8966 96.272L23.8826 97ZM17.4216 97L17.4216 87.9L18.7346 87.9L18.6956 88.966L18.6956 97L17.4216 97ZM23.8826 97L23.8826 87.9L25.1696 87.9L25.1696 97L23.8826 97ZM27.554 97L27.554 87.9L28.828 87.9L28.828 97L27.554 97ZM31.2117 97L31.2117 87.9L32.4857 87.9L32.4857 97L31.2117 97ZM32.2777 97L32.2777 95.83L36.7107 95.83L36.7107 97L32.2777 97ZM32.2777 92.918L32.2777 91.774L36.2687 91.774L36.2687 92.918L32.2777 92.918ZM32.2777 89.07L32.2777 87.9L36.6847 87.9L36.6847 89.07L32.2777 89.07Z"
                  fill="#686235"
                />
              </SvgButton>
            </Stack>
          </>
        )}
      </Stack>
    </StyledModal>
  )
}
